import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import AllTeam from "../components/AllTeam";
import Info from "../components/Info";
const captainsData = [
  {
    id: 1,
    name: "Ruturaj Gaikwad",
    img: "img/captains/1.png",
  },
  {
    id: 2,
    name: "Rishabh Pant",
    img: "img/captains/2.png",
  },
  {
    id: 3,
    name: "Shubman Gill",
    img: "img/captains/3.png",
  },
  {
    id: 4,
    name: "Shreyas Iyer",
    img: "img/captains/4.png",
  },
  {
    id: 5,
    name: "KL Rahul",
    img: "img/captains/5.png",
  },
  {
    id: 6,
    name: "Hardik Pandya",
    img: "img/captains/6.png",
  },
  {
    id: 7,
    name: "Sam Curran",
    img: "img/captains/7.png",
  },
  {
    id: 8,
    name: "Sanju Samson",
    img: "img/captains/8.png",
  },
  {
    id: 9,
    name: "Faf du Plessis",
    img: "img/captains/9.png",
  },
  {
    id: 10,
    name: "Anmolpreet Singh",
    img: "img/captains/10.png",
  },
];
const Players = () => {
  return (
    <>
      <Breadcrumb title="Players" />
      <section className="third-about-area third-about-bg pt-10 pb-90">
        <div className="container custom-container">
          <Info />
        </div>
      </section>
      <section className="team-area team-bg p-0">
        <div
          className="pt-110 pb-90"
          style={{ background: " rgba(1, 1, 28, 0.88)" }}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="third-section-title text-center mb-60">
                  <h2>
                    MEET <span>IPL</span> CAPTAINS
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {captainsData.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-8"
                  key={index}
                >
                  <div className="third-team-item text-center mb-30">
                    <div className="third-team-img">
                      <img className="w-100" src={item.img} alt="" />
                    </div>
                    <div className="third-team-content">
                      <div className="main-bg"></div>
                      <div className="hover-bg"></div>
                      <h5>{item.name}</h5>
                      <span>Captain</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <AllTeam />
    </>
  );
};

export default Players;
