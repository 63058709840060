import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="third-header-bg">
        <div className="bg"></div>
        <div className="container custom-container">
          <div className="header-top-area t-header-top-area d-none d-lg-block">
            <div className="row">
              <div className="col-sm-6">
                <div className="header-top-social">
                  {/* <ul>
                    <li>Follow</li>
                    <li>
                      <a to="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-vimeo-v"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="header-top-login">
                  <ul>
                    <li>
                      {/* <NavLink to="/register">
                        <i className="far fa-edit"></i>Register
                      </NavLink> */}
                    </li>
                    {/* <li className="or">or</li> */}
                    <li>
                      {/* <NavLink to="/login">
                        <i className="far fa-edit"></i>sign in
                      </NavLink> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-md-done d-block mob-top">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="main-menu menu-style-two">
                  <nav>
                    <div className="logo d-block d-lg-none">
                      <Link to="/">
                        <img
                          className="mb-res"
                          src="img/logo/h3_logo.png"
                          alt="Logo"
                        />
                      </Link>
                    </div>
                    <div className="navbar-wrap d-none d-lg-flex">
                      <ul className="left">
                        <li className="">
                          <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                          <NavLink to="/about">About Us</NavLink>
                        </li>
                        <li>
                          <NavLink to="/players">Players</NavLink>
                        </li>
                      </ul>
                      <div className="logo">
                        <Link to="/">
                          <img src="img/logo/h3_logo.png" alt="Logo" />
                        </Link>
                      </div>
                      <ul className="right">
                        <li>
                          <NavLink to="/play">How To Play</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">contact</NavLink>
                        </li>
                        <li>
                          <a href="/img/myimpact11.apk">Download</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="mobile-menu mean-container d-md-none d-block">
                <div className="mean-bar">
                  <a
                    href="#nav"
                    className="meanmenu-reveal"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                  <div
                    className="offcanvas offcanvas-end"
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      background: "url(img/about2.jpg)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div style={{ background: "#07022ae8", height: "100%" }}>
                      <div className="offcanvas-header">
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          style={{ filter: "invert(100%)" }}
                        ></button>
                      </div>
                      <div className="offcanvas-body">
                        <div className="mean-bar">
                          <nav className="mean-nav">
                            <ul>
                              <li className="show">
                                <Link to="/" data-bs-dismiss="offcanvas"
                                  aria-label="Close">Home</Link>
                              </li>
                              <li>
                                <Link to="/about" data-bs-dismiss="offcanvas"
                                  aria-label="Close">About Us</Link>
                              </li>
                              <li>
                                <Link to="/players" data-bs-dismiss="offcanvas"
                                  aria-label="Close">Players</Link>
                              </li>
                              <li>
                                <Link to="/play" data-bs-dismiss="offcanvas"
                                  aria-label="Close">How To Play</Link>
                              </li>
                              <li>
                                <Link to="/contact"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close">contact</Link>
                              </li>
                              <li>
                                <a href="/img/myimpact11.apk"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close">Download</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
