import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="home-six-footer">
        <div className="footer-top footer-bg">
          <div className="newsletter-area s-newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="newsletter-wrap">
                    <div className="section-title newsletter-title">
                      <h2>
                        Our <span>Newsletter</span>
                      </h2>
                    </div>
                    <div className="newsletter-form">
                      <form action="#">
                        <div className="newsletter-form-grp">
                          <i className="far fa-envelope"></i>
                          <input
                            type="email"
                            placeholder="Enter your email..."
                          />
                        </div>
                        <button>
                          SUBSCRIBE <i className="fas fa-paper-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <Link to="/">
                      <img src="img/logo/h3_logo.png" alt="" style={{ width: "100px" }} />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <i className="fas fa-map-marker-alt"></i>{" "}
                          <span>Address : </span>TKJS Fintech PVT. LTD. Anant
                          Sai Apartment, Diva, Thane: 400612
                        </li>
                        <li>
                          <i className="fas fa-headphones"></i>{" "}
                          <span>Phone : </span>+91 9137427028
                        </li>
                        <li>
                          <i className="fas fa-envelope-open"></i>
                          <span>Email : </span> support@myimpact11.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Pages</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/players">Players</Link>
                      </li>
                      <li>
                        <Link to="/play">How To Play</Link>
                      </li>
                      <li>
                        <Link to="/contact">contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Need Help?</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li>
                        <Link to="/terms">Terms &amp; Conditions</Link>
                      </li>
                      <li>
                        <Link to="/policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/refund">Refund and Cancellation Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-pinterest-p"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Newsletter Sign Up</h5>
                  </div>
                  <div className="footer-newsletter">
                    <form action="#">
                      <input type="text" placeholder="Enter your email" />
                      <button>
                        <i className="fas fa-rocket"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-fire">
            {/* <img src="img/images/footer_fire.png" alt="" /> */}
          </div>
          <div className="footer-fire footer-fire-right">
            {/* <img src="img/images/footer_fire.png" alt="" /> */}
          </div>
        </div>
        <div className="copyright-wrap s-copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>
                    Copyright © 2020 <a href="#">myimpact11</a> All Rights
                    Reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-none d-md-block">
                <div className="payment-method-img text-right">
                  <img src="img/images/card_img.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
