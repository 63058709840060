import React from "react";

const ContactUs = () => {
  return (
    <>
      <section className="contact-area third-contact-bg">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="third-contact-wrap">
                <div className="third-title-style">
                  <h2 style={{ color: "#000" }}>
                    contact<span> us</span>
                  </h2>
                  <div className="inner">
                    <h2 style={{ color: "#000" }}>Get in touch</h2>
                    <p className="mb-3">
                      We are here for you! how can we help, We are here for you!
                    </p>
                    <h6 className="vertical-title">my impact 11</h6>
                    <ul>
                      <li>
                        our location : TKJS Fintech PVT. LTD. Anant Sai
                        Apartment, Diva, Thane: 400612
                      </li>
                      <li>Phone : +91 9137427028</li>
                      <li>
                        <span>Email :</span> support@myimpact11.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form action="#" className="third-contact-form">
                <div className="row">
                  <div className="col-sm-6">
                    <input type="text" placeholder="Your Name" />
                  </div>
                  <div className="col-sm-6">
                    <input type="text" placeholder="Your Phone" />
                  </div>
                </div>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Write Message"
                ></textarea>
                <button className="btn rotated-btn">submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
