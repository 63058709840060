import React from "react";

const Register = () => {
  return (
    <>
      <section
        class="third-about-area third-about-bg pt-0 pb-90"
        style={{ marginTop: "-18px" }}
      >
        <div class="container custom-container">
          <div class="see-my-info-wrap pt-110">
            <div class="row">
              <div class="col-12">
                <div class="third-section-title text-center mb-75">
                  <h2 style={{ color: "rgb(0, 0, 0)" }}>
                    User <span>Register</span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-4 col-lg-6 col-sm-8">
                <div class="my-info-box mb-30">
                  <div class="my-info-box-top">
                    <h6>User Register</h6>
                    <img
                      src="img/bg/my_info_box_hover.png"
                      alt=""
                      class="info-box-top-hover"
                    />
                  </div>
                  <div class="my-info-box-content">
                    <div class="mt-award">
                      <form action="#" class="third-contact-form">
                        <div class="row">
                          <div class="col-sm-12">
                            <input type="text" placeholder="Your Name" />
                          </div>
                          <div class="col-sm-12">
                            <input type="text" placeholder="User Name" />
                          </div>
                          <div class="col-sm-12">
                            <input
                              type="text"
                              placeholder="Your Phone Number"
                            />
                          </div>
                          <div class="col-sm-12">
                            <input
                              type="password"
                              placeholder="Your Password"
                            />
                          </div>
                        </div>
                        <button class="btn rotated-btn">REGISTER</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
