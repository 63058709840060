import React from "react";

const Info = () => {
  return (
    <>
      <div className="see-my-info-wrap pt-110">
        <div className="row">
          <div className="col-12">
            <div className="third-section-title text-center mb-75">
              <h2 style={{ color: "#000" }}>
                How <span>To</span> Play
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-sm-8">
            <div className="my-info-box mb-30">
              <div className="my-info-box-top">
                <h6>Select a Match</h6>
                <img
                  src="img/bg/my_info_box_hover.png"
                  alt=""
                  className="info-box-top-hover"
                />
              </div>
              <div className="my-info-box-content">
                <div className="mt-award">
                  <p style={{ color: "#fff" }}>
                    Select an upcoming match of your choice
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-sm-8">
            <div className="my-info-box mb-30">
              <div className="my-info-box-top">
                <h6>Join Contests</h6>
                <img
                  src="img/bg/my_info_box_hover.png"
                  alt=""
                  className="info-box-top-hover"
                />
              </div>
              <div className="my-info-box-content">
                <p style={{ color: "#fff" }}>
                  Participate in Cash or Practice Contests.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-sm-8">
            <div className="my-info-box mb-30">
              <div className="my-info-box-top">
                <h6>Create your team</h6>
                <img
                  src="img/bg/my_info_box_hover.png"
                  alt=""
                  className="info-box-top-hover"
                />
              </div>
              <div className="my-info-box-content">
                <p style={{ color: "#fff" }}>
                  Use your sports knowledge and check player stats on the
                  platform to create a team using 100 credits
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
