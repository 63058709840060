import React from 'react'
import Breadcrumb from '../components/Breadcrumb'

const Refund = () => {
    return (
        <>
            <Breadcrumb title="Refund and Cancellation Policy" />
            <section className="contact-area third-contact-bg">
                <div className="container custom-container">
                    <div className="col-lg-12">
                        <h3 className="text-dark">Refund Policy</h3>
                        <p className="text-dark">We at TKJS Fintech PVT. LTD. strive to ensure your satisfaction with our services. However, we understand that there may be instances where you need to request a refund. Please review our refund policy below:</p>
                        <h4 className="mt-4 text-dark">Refund Eligibility:</h4>
                        <p>Refunds are only applicable for purchases made directly through our website.</p>
                        <p>Refunds must be requested within 14 days of purchase.</p>
                        <p>Refunds are not applicable for in-game purchases, virtual goods, or items that have already been redeemed.</p>

                        <h4 className="mt-4 text-dark">Refund Process:</h4>
                        <p>To request a refund, please contact our customer support team at s2kfintechsolution@gmail.com with your purchase details.</p>
                        <p>Our team will review your request and, if eligible, process the refund within 7-10 business days.</p>

                        <h4 className="mt-4 text-dark">Refund Method:</h4>
                        <p>Refunds will be issued via the original payment method used for the purchase.</p>
                        <p>Refunds for purchases made via credit/debit card may take 3-5 business days to reflect in your account.</p>

                        <br /><br />

                        <h3 className="text-dark">Cancellation Policy</h3>
                        <h4 className="mt-4">Cancellation by User:</h4>
                        <p>You may cancel your subscription or service at any time by contacting our customer support team.</p>
                        <p>Cancellations must be requested at least 24 hours before the next billing cycle to avoid being charged for the next period.</p>

                        <h4 className="mt-4 text-dark">Cancellation by Fantasy Gaming:</h4>
                        <p>We reserve the right to cancel or suspend your account or services at any time for violation of our terms of service or for any other reason deemed necessary by Fantasy Gaming.</p>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Refund