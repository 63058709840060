import React from "react";

const Steps = () => {
  return (
    <>
      <section className="just-gamers-area just-gamers-bg ">
        <div
          className="w-100 just-gamers-bgs pt-115 pb-120"
          style={{ background: " #02061c" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-10">
                <div className="section-title title-style-three white-title mb-70">
                  <h2>
                    Features <span> We Provide</span>
                  </h2>

                  <p>
                    Compete with 100 player on a remote island for winner known
                    issue where certain strategic
                  </p>
                </div>
                <div className="just-gamers-list">
                  <ul>
                    <li>
                      <div className="just-gamers-list-icon text-center">
                        <h1 className="mt-4">1</h1>
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>Earn Real Money</h5>
                        <p style={{ color: "#fff" }}>
                          Play Cricket Online to Earn Real Money.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="just-gamers-list-icon text-center ">
                        <h1 className="mt-4">3</h1>
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>Fair Game Play</h5>
                        <p style={{ color: "#fff" }}>
                          myimpact11 assures each & every game to be Fair.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 d-none d-lg-block">
                <div
                  className="just-gamers-img text-center"
                  style={{ position: "relative" }}
                >
                  <img
                    src="img/images/just_gamers_img.png"
                    alt=""
                    className="just-gamers-character"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-10">
                <div className="just-gamers-list mt-207">
                  <ul>
                    <li>
                      <div className="just-gamers-list-icon">
                        <h1 className="mt-4">2</h1>
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>Instant Withdrawal</h5>
                        <p style={{ color: "#fff" }}>
                          You can get your money in Just One click withdrawal.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="just-gamers-list-icon">
                        <h1 className="mt-4">4</h1>
                      </div>
                      <div className="just-gamers-list-content fix">
                        <h5>Secure</h5>
                        <p style={{ color: "#fff" }}>
                          myimpact11 is 100% Safe & Secure.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps;
