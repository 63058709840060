import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import AboutSec from "../components/AboutSec";
import AllTeam from "../components/AllTeam";
import Team from "../components/Team";

const About = () => {
  return (
    <>
      <Breadcrumb title="About Us" />
      <AboutSec />
      <Team />
      <AllTeam />
    </>
  );
};

export default About;
