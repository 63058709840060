import React from "react";

import OwlCarousel from "react-owl-carousel";

const AllTeam = () => {
  const allTeamData = [
    {
      id: 1,
      img: "img/team/1.png",
    },
    {
      id: 2,
      img: "img/team/2.png",
    },
    {
      id: 3,
      img: "img/team/3.png",
    },
    {
      id: 4,
      img: "img/team/4.png",
    },
    {
      id: 5,
      img: "img/team/5.png",
    },
    {
      id: 6,
      img: "img/team/6.png",
    },
    {
      id: 7,
      img: "img/team/7.png",
    },
    {
      id: 8,
      img: "img/team/8.png",
    },
    {
      id: 9,
      img: "img/team/9.png",
    },
    {
      id: 10,
      img: "img/team/10.png",
    },
  ];

  const options = {
    loop: true,
    items: 8,
    margin: 20,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 8,
      },
    },
  };
  return (
    <>
      <div className="brand-area t-brand-bg" style={{ background: "#fff" }}>
        <div className="container custom-container">
          <OwlCarousel className="owl-theme" {...options}>
            {allTeamData.map((item, index) => (
              <div className="item" key={index}>
                <img className="w-75" src={item.img} alt="" />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default AllTeam;
