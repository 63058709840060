import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Info from "../components/Info";
import Steps from "../components/Steps";
import AllTeam from "../components/AllTeam";

const Play = () => {
  return (
    <>
      <Breadcrumb title="How To Play" />
      <section className="third-about-area third-about-bg pt-10 pb-90">
        <div className="container custom-container">
          <Info />
        </div>
      </section>
      <Steps />
      <AllTeam />
    </>
  );
};

export default Play;
