import React from "react";

const Hero = () => {
  return (
    <>
      <section className="">
        <img
          className="w-100"
          src="img/bg/337.png"
          alt=""
          style={{ marginTop: "-25px" }}
        />
      </section>
    </>
  );
};

export default Hero;
