import React from "react";
import { Link } from "react-router-dom";

const Match = () => {
  return (
    <>
      <section className="my-match-area my-match-bg pt-0">
        <div
          className="pb-120 pt-100"
          style={{ background: "rgba(1, 1, 28, 0.88)" }}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="third-section-title text-center mb-75">
                  <h2>
                    see <span>my</span> MATCHES
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="my-match-wrap">
                  <div
                    className="my-match-box-wrap wow fadeInDown"
                    data-wow-delay=".2s"
                  >
                    <img
                      src="img/bg/my_match_box.png"
                      alt=""
                      className="match-box-bg"
                    />
                    <ul>
                      <li>
                        <div className="my-match-team">
                          <div className="team-one">
                            <Link href="#">
                              <img
                                src="img/team/1.png"
                                alt=""
                                style={{ width: "94px" }}
                              />
                            </Link>
                          </div>
                          <div className="vs">
                            <img src="img/team/match_vs02.png" alt="" />
                          </div>
                          <div className="team-one">
                            <Link href="#">
                              <img
                                className="w-50"
                                src="img/team/2.png"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="my-match-info">
                          <Link to="/" target="_blank" className="live-btn">
                            Live MATCHES
                          </Link>
                          <h5>CSK VS DC</h5>
                          <span>31 Mar 2024.</span>
                        </div>
                      </li>
                      <li>
                        <Link to="/" target="_blank" className="watch-stream">
                          <i className="fas fa-podcast"></i> watch stream
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="my-match-box-wrap wow fadeInDown"
                    data-wow-delay=".6s"
                  >
                    <img
                      src="img/bg/my_match_box.png"
                      alt=""
                      className="match-box-bg"
                    />
                    <ul>
                      <li>
                        <div className="my-match-team">
                          <div className="team-one">
                            <Link href="#">
                              <img
                                src="img/team/5.png"
                                alt=""
                                style={{ width: "94px" }}
                              />
                            </Link>
                          </div>
                          <div className="vs">
                            <img src="img/team/match_vs02.png" alt="" />
                          </div>
                          <div className="team-one">
                            <Link href="#">
                              <img
                                className="w-50"
                                src="img/team/6.png"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="my-match-info">
                          <a
                            href="https://www.twitch.tv/shroud"
                            className="live-btn"
                          >
                            Live MATCHES
                          </a>
                          <h5>LSG VS PANJAB KINGS</h5>
                          <span>30 Mar 2024.</span>
                        </div>
                      </li>
                      <li>
                        <a
                          href="https://www.twitch.tv/shroud"
                          className="watch-stream"
                        >
                          <i className="fas fa-podcast"></i> watch stream
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="my-match-box-wrap wow fadeInDown"
                    data-wow-delay=".4s"
                  >
                    <img
                      src="img/bg/my_match_box.png"
                      alt=""
                      className="match-box-bg"
                    />
                    <ul>
                      <li>
                        <div className="my-match-team">
                          <div className="team-one">
                            <Link to="/">
                              <img
                                src="img/team/3.png"
                                alt=""
                                style={{ width: "94px" }}
                              />
                            </Link>
                          </div>
                          <div className="vs">
                            <img src="img/team/match_vs02.png" alt="" />
                          </div>
                          <div className="team-one">
                            <Link to="/">
                              <img
                                className="w-50"
                                src="img/team/4.png"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="my-match-info">
                          <a
                            href="https://www.twitch.tv/shroud"
                            className="live-btn"
                          >
                            Live MATCHES
                          </a>
                          <h5>GT VS KKR</h5>
                          <span>13 Mar 2024.</span>
                        </div>
                      </li>
                      <li>
                        <a
                          href="https://www.twitch.tv/shroud"
                          className="watch-stream"
                        >
                          <i className="fas fa-podcast"></i> watch stream
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Match;
