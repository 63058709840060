import React from "react";
import Breadcrumb from "../components/Breadcrumb";

const Privacy = () => {
  return (
    <>
      <Breadcrumb title="Privacy Policy" />
      <section className="contact-area third-contact-bg">
        <div className="container custom-container">
          <div class="commantext">
            <h3 style={{ color: "#000" }}>Privacy Policy</h3>
            <p>
              Welcome to myimpact11 Your privacy is important to us. This
              Privacy Policy explains how we collect, use, and protect your
              personal information when you use our website and services.
            </p>
          </div>
          <div>
            <div class="commanOther">
              <h3>
                TKJS FINTECH PRIVATE LIMITED is the brand of Privacy Policy
              </h3>
              <p style={{ whiteSpace: "preWrap" }}>
                We are the flagship brand of TKJS FINTECH PRIVATE LIMITED,
                offering Our Services to You wherein You can also participate in
                various Contests hosted on Our Platform. Any person utilizing
                the Platform or any of its features, including participation in
                various Contests being conducted on the Platform, shall be bound
                by the terms of this privacy policy (“Privacy Policy”). <br />
                All capitalized terms not defined herein shall have the meaning
                ascribed to them in the Terms.
                <br />
                <br /> We respect the privacy of its User(s) and are committed
                to protecting it in all respects. With a view to offer an
                enriching and holistic internet experience to its Users, We
                offer a vast repository of Services on Our Platform. Kindly take
                time to read the “About Us” section to know more. Most of the
                Services are offered for free but you need registration to
                participate in the Contests. The information about the User(s)
                is collected by Us as (i) information supplied by User(s) and
                (ii) information automatically tracked during User(s)’
                navigation on the Platform.
                <br />
                <br /> Before you submit any information on the Platform, please
                read this Privacy Policy for an explanation of how We will treat
                Your information. By using any part of the Platform, You consent
                to the collection, use, disclosure and transfer of Your
                information for the purposes outlined in this Privacy Policy and
                to the collection, processing and maintenance of this
                information. Your use of any part of the Platform indicates Your
                acceptance of this Privacy Policy and of the collection, use and
                disclosure of Your information in accordance with this Privacy
                Policy.
                <br />
                <br /> While You have the option not to provide Us with certain
                personal information, withdraw Your consent to collect certain
                information, request temporary suspension of collection of
                personal information or request deletion of personal information
                collected, kindly note that in such an event You may not be able
                to take full advantage of the entire scope of features and
                services offered to You and We reserve the right not to provide
                You with Our services. <br />
                <br />
                In the ever-evolving medium of the internet, We reserve our
                right to periodically review and change Our Privacy Policy to
                incorporate such future changes as may be considered appropriate
                and under applicable laws. We will notify You of the change. Any
                changes to Our Privacy Policy will be posted on this page, so
                You are always aware of what information We collect, how We use
                it, how We store it and under what circumstances We disclose it.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Purpose and Usage:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                To avail certain features on the Platform, Users may be required
                to provide the following information for the registration
                process, namely: <br />
                1. Username <br />
                2. Email address <br />
                3. Date of birth <br />
                4. State <br />
                5. Government ID such as Aadhaar card, driving license, or voter
                ID. <br /> <br />
                Additionally, in the course of providing You with access to the
                Platform and the Services offered by Us, You agree to give Us
                permission to capture and record Your device-related
                information, operating system information, network information,
                location information, etc. You may choose to allow Us to gather
                information related to the apps installed on Your device to
                enhance Our Services and Your experience on the Platform. You
                may also be required to furnish additional information,
                including Your Permanent Account Number. <br />
                <br />
                In certain instances, We may also collect sensitive personal
                information (SPI) from You on the Platform. SPI includes
                information relating to Your financial information, such as
                information regarding the payment instrument/modes used by You
                to make such payments, which may include cardholder name,
                credit/debit card number (in encrypted form) with expiration
                date, banking details, wallet details, etc. This information is
                collected at the time of making a payment to facilitate Your
                payment process. <br />
                <br />
                Except for any financial information that You choose to provide
                while making payment for any Services on the Platform, We do not
                collect any other SPI in the course of providing the Services.
                Any SPI collected by Us shall not be disclosed to any
                third-party service provider without Your express consent,
                except as otherwise set out in this Privacy Policy or as
                provided in a separate written agreement between Us and You, or
                as required by law. This condition does not apply to publicly
                available information, including SPI, relating to You on the
                Platform.
                <br />
                <br /> We collect and use personal information provided by You
                (that is, information that may be used to identify You and that
                is not otherwise publicly available) to operate, provide,
                develop, and improve our Services, keep you informed of Our
                offerings, and the products and/or services of Our affiliates
                and group entities, and to enable Our affiliates and group
                entities to reach out to You regarding the products and services
                they offer.
                <br /> <br />
                In the course of providing the Services, User(s) may invite
                other existing User(s) or other users (“Invited Users”) to
                participate in any of the Services through any social media
                platform, including but not limited to Facebook, WhatsApp,
                Instagram, etc. We may use this information to contact the
                Invited User and invite them to register with Our Platform (if
                they are not already a User) and participate in the Contest they
                were invited to by You. The participation of the Invited User in
                any Contest is subject to the terms of this Privacy Policy and
                the Terms for the use of the Platform. You represent that the
                Invited Users have consented and agreed to such disclosure of
                their information to Us, Our affiliates, and/or Our group
                entities. You may also choose to invite Your friends by syncing
                your phonebook and inviting them directly from the Platform to
                utilize Our Services and/or to participate in Contests.
                <br />
                <br /> All required information is specific and based on the
                kind of Services and/or Contests the User wishes to participate
                in or access and will be utilized to provide the same.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Disclosure/Sharing:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                We may share information provided by You and data concerning
                Your usage of the Services and participation in the Contests
                with Our affiliates and group entities to enable them to reach
                out to you regarding the products and/or services they offer. We
                may also share such information with third-party service
                providers engaged by Us for data analytics, storage, service
                improvements (including product enhancements), and to better
                serve you. Any affiliate, group entity, or third-party service
                provider with whom the information is shared will be required,
                to the best of Our efforts, to comply with the same standards
                applicable to Us in maintaining the security of the information.
                <br />
                <br />
                By using the Platform, You expressly agree and consent to the
                collection, use, and storage of Your information by Us. We
                reserve the right to share, disclose, and transfer information
                collected hereunder with Our affiliates and group entities. In
                the event We sell or transfer all or a portion of Our business
                assets, consumer information may be one of the business assets
                shared, disclosed, or transferred as part of the transaction.
                You expressly grant consent and permission to Us for the
                disclosure and transfer of personal information for this
                purpose.
                <br />
                <br /> We may also share information and data concerning the
                usage of the Services and participation in the Contests with Our
                commercial partners for facilitating user engagement, marketing,
                promotional purposes, and other related purposes. <br />
                <br />
                Furthermore, We reserve the right to disclose or share
                information with affiliates, group entities, and third parties
                in limited circumstances, including for complying with
                applicable law, responding to duly authorized legal processes,
                governmental requests, preventing fraud or imminent harm, and
                ensuring the security of Our network and services.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Use of Cookies:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                To improve the effectiveness and usability of the Platform for
                our Users, We use “cookies”, or such similar electronic tools,
                to collect information to assign each visitor a unique random
                number as a User Identification (User ID) to understand the
                User's individual interests using the identified device. Unless
                the User voluntarily identifies himself/herself (e.g., through
                registration), We have no way of knowing who the User is, even
                if we assign a cookie to the User’s device. The only information
                a cookie can contain is information supplied by the User. A
                cookie cannot read data off the User’s device hard drive. Our
                advertisers may also assign their own cookies to the User's
                device (if the User clicks on their ad banners), a process that
                We do not control.
                <br />
                <br /> Our web servers automatically collect limited information
                about User’s device connection to the Internet, including User’s
                IP address, when the User(s) uses the Platform (User's IP
                address is a number that lets devices attached to the Internet
                know where to send data to the User -- such as the web pages
                viewed by the User). The User's IP address does not identify the
                User personally. We use this information to deliver our web
                pages to User(s) upon request, to tailor our Platform to the
                interests of Our User(s), to measure traffic within the
                Platform, and to let advertisers know the geographic locations
                from where Our visitors come.
                <br />
                <br /> By using the Platform, You consent to the use of cookies
                and the collection of Your IP address as described above. You
                may adjust Your browser settings to refuse cookies, but this may
                limit Your ability to access certain features of the Platform.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Links:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                We also include links to other websites on Our Platform. Such
                websites are governed by their respective privacy policies,
                which are beyond Our control. Once the User(s) leaves Our
                servers (the User can tell where he/she is by checking the URL
                in the location bar on the User's browser), the use of any
                information provided by the User is governed by the privacy
                policy of the operator of the site which the User is visiting.
                That policy may differ from Our Privacy Policy. If the User
                can't find the privacy policy of any of these sites via a link
                from the site's homepage, the User may contact the site directly
                for more information. We are not responsible for the privacy
                practices or the content of such third-party websites.
                <br />
                <br /> By providing links to other websites, We do not endorse
                the content, products, or services offered through those
                websites, nor do We assume any responsibility or liability for
                the actions, privacy practices, or content of such websites.
                <br />
                <br /> It is recommended that Users review the privacy policies
                of any third-party websites they visit through links provided on
                Our Platform to understand their practices regarding the
                collection and use of personal information.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Security Procedures:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                All information gathered on Our Platform is securely stored
                within a controlled database. The database is stored on servers
                secured behind a firewall; access to such servers being
                password-protected and strictly limited based on need-to-know
                basis. However, we understand that as effective as Our security
                measures are, no security system is impenetrable. Thus, We
                cannot guarantee the security of Our database, nor can We
                guarantee that information You supply will not be intercepted
                while being transmitted to Us over the internet. Further, any
                information You include in a posting to the discussion areas
                will be available to anyone with internet access. By using the
                Platform, you understand and agree that Your information may be
                used in or transferred to countries other than India
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Advertising:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                When We present information to Our online advertisers - to help
                them understand Our audience and confirm the value of
                advertising on the Platform - it is usually in the form of
                aggregated anonymized data (information that cannot be used to
                trace a user) of User(s) that may include statistics and/or data
                concerning User traffic, User responsiveness and User behaviour
                to such advertisements on our Platform. When you register with
                Us, We contact you from time to time about updating Your content
                to provide features which we believe may benefit You.
              </p>
            </div>
            <div class="commanOther">
              <h3>Disclaimer:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                Several deceptive emails, websites, blogs etc. claiming to be
                from or associated with Our Platform may or are circulating on
                the Internet (“Deceptive Communication”). Such Deceptive
                Communication may include our logo, photos, links, content or
                other information. Some Deceptive Communication may call the
                User(s) requesting the User(s) to provide login name, password
                etc. or inform the User(s) that the User(s) has won a prize/
                gift or provide a method to commit an illegal/ unauthorized act
                or deed or request detailed personal information or a payment of
                some kind. The sources and contents of these Deceptive
                Communications and accompanying materials are in no way
                associated with Us. For Your own protection, We strongly
                recommend not responding to such Deceptive Communication without
                verifying the genuineness. You agree that We shall not be liable
                for any loss, damage and harm that You may suffer by relying
                and/or acting upon such Deceptive Communications <br />
                <br />
                Except, as provided for the purpose of verification, as set out
                under the Terms, We and/or Our employees will not contact You to
                divulge your SPI
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Conditions of Use:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                WE DO NOT WARRANT THAT THIS PLATFORM, OUR SERVERS, OR EMAIL SENT
                BY US OR ON OUR BEHALF ARE VIRUS FREE. WE WILL NOT BE LIABLE FOR
                ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PLATFORM,
                INCLUDING, BUT NOT LIMITED TO COMPENSATORY, DIRECT, INDIRECT,
                INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF
                DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF
                OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT
                WILL WE BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN
                EXCESS OF AN AMOUNT OF INR 100.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Retention of Data:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                Your personal information may be retained and may continue to be
                used until: (i) the relevant purposes for the use of Your
                personal information described in this Privacy Policy are no
                longer applicable; and (ii) We are no longer required by
                applicable law, regulations, contractual obligations or
                legitimate business purposes to retain Your personal information
                and the retention of Your personal information is not required
                for the establishment, exercise or defense of any legal claim.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>User Account and Data Deletion:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                User(s) are entitled to request Us to delete their accounts and
                their personal information by sending an email with their
                written request to support@myimpact11.com <br />
                <br />
                We will do our best to respond promptly and in any event within
                one month of the following:
                <br />
                <br /> • - Our receipt of Your written request; or <br />• - Our
                receipt of any further information We may ask you to provide, to
                enable Us to comply with Your request, whichever is later.{" "}
                <br />
                <br />
                As an alternative to account deletion, by writing to
                support@myimpact11.com, You also have the option to request
                either: <br />
                <br />
                i. the suspension of Your account, after which You will not be
                able to play paid contests on your User account but will
                continue to have access to other parts of the Platform; or{" "}
                <br />
                ii. temporary deactivation of Your account, where you will no
                longer be able to log into the Platform but which allows You to
                request reactivation of your account with all Your account data
                <br />
                <br />
                If You proceed with permanent deactivation of your account, you
                will lose access to the Platform and the Services, including any
                User data and personal information associated with Your account.
                User(s) may request for account restoration within six (6)
                months from the date of notification of account deletion by
                writing to Us on support@myimpact11.com <br />
                <br />
                When You request deletion of Your data, We follow a deletion
                process that ensures that Your data is safely and completely
                removed from Our servers or retained only in anonymised form. We
                try to ensure that Our services protect information from
                accidental or malicious deletion. Because of this, there may be
                delays between when You request deletion and when copies are
                deleted from our active and backup systems.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>
                Applicable Law and Jurisdiction:
              </h3>
              <p style={{ whiteSpace: "preWrap" }}>
                By visiting this Platform, you agree that the laws of the
                Republic of India without regard to its conflict of laws
                principles, govern this Privacy Policy and any dispute arising
                in respect hereof shall be subject to and governed by the
                dispute resolution process set out in the Terms
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Updating Information:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                You will promptly notify Us if there are any changes, updates or
                modifications to Your personal information. Further, You may
                also review, update or modify Your personal information and User
                preferences by logging into Your profile page on the Platform.
              </p>
            </div>
            <div class="commanOther">
              <h3 style={{ color: "#000" }}>Contact Us:</h3>
              <p style={{ whiteSpace: "preWrap" }}>
                Any questions or clarifications with respect to this Privacy
                Policy or any complaints, comments, concerns or feedback can be
                sent to Us at: support@myimpact11.com or by normal/physical mail
                addressed to: Attn: TKJS FINTECH PRIVATE LIMITED Address: Anant
                Sai Apartment, Diva, Thane: 400612
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
