import React from "react";
import Info from "./Info";

const AboutSec = () => {
  return (
    <>
      <section className="third-about-area third-about-bg pt-120 pb-90">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-0 order-lg-2"
              style={{ overflow: "hidden" }}
            >
              <div className="third-about-img text-right position-relative">
                <img className="w-100" src="img/banner.webp" alt="" />
                <img
                  src="img/images/third_about_img_shadow.png"
                  className="shadow"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="third-about-content">
                <div className="third-title-style">
                  <h2 style={{ color: "#000" }}>
                    About<span>Us</span>
                  </h2>
                  <div className="inner">
                    <h2 style={{ color: "#000" }}>
                      Play Fantasy game on myimpact11
                    </h2>
                    <h6 className="vertical-title">My impact 11</h6>
                    <p>
                      Want to enjoy fantasy games like cricket but just can't
                      manage the time? Well, myimpact11 is the answer you need.
                      This is the place where your favorite fantasy sports come
                      alive. Enjoy playing fantasy cricket right on your device.
                      Pick teams of your choice and play the game.
                    </p>
                    <p>
                      At this platform, We Provide only the best UI and Support
                      completely with passion simplicity and creativity!
                    </p>
                    <p>
                      Fantasy cricket is a strategy based online sports game
                      where players create a virtual team of real cricketers
                      playing in real matches worldwide. You earn points based
                      on the performances of these players in actual matches. In
                      a fantasy cricket match, you can create a team by choosing
                      11 players where you can pick a maximum of 7 players from
                      any one team, playing the real-life match.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Info />
        </div>
      </section>
    </>
  );
};

export default AboutSec;
